import React, { useState, useMemo, Fragment } from 'react'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { navigateTo } from '../../services/utils'

export const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  sidebar: theme.mixins.toolbar,
  sectionHeader: {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    border: 'none',
    color: 'inherit',
    background: 'transparent',
    fontFamily: 'inherit',
    fontSize: '1rem',
    fontWeight: 500,
    textAlign: 'left',
    cursor: 'pointer',

    '& > svg': {
      position: 'absolute',
      top: 16,
      right: 12,
    },
  },
}))

const pathSets = [
  {
    title: 'Connect',
    paths: [
      {
        path: '/contracts',
        text: 'Campaigns',
      },
      {
        path: '/tools/contracts-ended',
        text: 'Closed campaigns',
      },
      {
        path: '/rules',
        text: 'Campaign Rules',
      },
      {
        path: '/attributions',
        text: 'Attribution Manager',
      },
      {
        path: '/attribution_presets',
        text: 'Attribution Presets',
      },
    ],
  },
  {
    title: 'Conversions',
    paths: [
      {
        path: '/conversion_events',
        text: 'Events',
      },
      {
        path: '/conversions',
        text: 'Conversions',
      },
    ],
  },
  {
    title: 'Accounts',
    paths: [
      {
        path: '/outbound',
        text: 'Outbound',
      },
      {
        path: '/inbound',
        text: 'Inbound',
      },
      {
        path: '/admin_users',
        text: 'Admin Users',
      },
    ],
  },
  {
    title: 'Onboard',
    paths: [
      {
        path: '/onboard/cms',
        text: 'CMS list',
      },
    ],
  },
  {
    title: 'Tools',
    paths: [
      {
        path: '/tools/outbound-checker',
        text: 'Outbound link checker',
      },
      // {
      //   path: '/tools/queue-checker',
      //   text: 'Conversions queue',
      // },
    ],
  },
  {
    title: 'Settings',
    paths: [
      {
        path: '/articles',
        text: 'Help Articles',
      },
      {
        path: '/doc_articles',
        text: 'Docs Articles',
      },
      {
        path: '/settings/conversion-safeguard',
        text: 'Conversion Safeguard',
      },
      {
        path: '/settings/login-css',
        text: 'Custom Advertiser CSS',
      },
      {
        path: '/settings/direct-css',
        text: 'Custom Publisher CSS',
      },
    ],
  },
  {
    title: 'Scriptmanager',
    paths: [
      {
        path: '/ga_accounts',
        text: 'GA Accounts',
      },
      {
        path: '/ga_attributions',
        text: 'GA Attributions',
      },
    ],
  },
]

export default function Sidebar(props) {
  const classes = useStyles()

  const isCurrentPage = (path) => window.location.pathname.startsWith(path)

  const initialOpen = useMemo(() => {
    for (const index in pathSets) {
      for (const item of pathSets[index].paths) {
        if (window.location.pathname.startsWith(item.path)) {
          return parseInt(index)
        }
      }
    }
    return 0
  }, [])
  const [opens, setOpens] = useState([initialOpen])
  const toggle = (index) => {
    const newOpens = [...opens]
    if (opens.includes(index)) {
      newOpens.splice(opens.indexOf(index), 1)
    } else {
      newOpens.push(index)
    }
    setOpens(newOpens)
  }

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
    >
      <div className={classes.sidebar} />
      <Divider />
      <List>
        {pathSets.map((pathSet, index) => (
          <Fragment key={pathSet.title}>
            <button
              className={classes.sectionHeader}
              onClick={() => toggle(index)}
            >
              {pathSet.title}
              {opens.includes(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </button>
            {opens.includes(index) &&
              pathSet.paths.map((item) => (
                <ListItem
                  key={item.path}
                  button
                  selected={isCurrentPage(item.path)}
                  onClick={(event) => {
                    navigateTo(event, item.path)
                  }}
                >
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      style: {
                        fontSize: 14,
                      },
                    }}
                  />
                </ListItem>
              ))}
          </Fragment>
        ))}
      </List>
    </Drawer>
  )
}
